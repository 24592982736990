body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ADDED CODE 8/25/2024 - Michelle Tam
/* -------------------------------------------------------------------- */
.platinumTier {
  color: #6c757d; /* Matches bg-secondary */
}

.tier-box {
  border: 2px solid #6c757d; /* Gray border */
  border-radius: 8px; /* Rounded corners */
  background-color: #f8f9fa; /* Light gray background */
  padding: 10px 20px; /* Space inside the box */
  display: inline-block; /* Make the box fit the text size */
  margin-bottom: 20px; /* Space below the box */
  text-align: center; /* Center align the text */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds a shadow effect */
}

.carousel {
  width: 100%;
  height: 350px; /* Matches the intended image height */
  overflow: hidden;
}

.carousel.slide {
  height: 350px; /* Ensures the carousel container has the correct height */
  overflow: hidden; /* Prevents unwanted overflow */
}

.carousel-item img {
  width: 100%;
  height: 350px;
  object-fit: cover; /* Ensures the image fills the area proportionally */
  display: block; /* Ensures the image is treated as a block-level element */
}

.carousel.slide {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #7859AD; /* Set the background color */
  filter: none; /* Remove default grayscale filter */
}


.light-purple {
  background-color: #dab7f17c; /* Light purple */
  padding: 0;
  margin: 0;
  height: auto;
  width: 100%;
}

.light-purple2 {
  background-color: #ecd9f888; /* Light purple */
  padding: 0 !important;
  margin: 0 !important;
  height: auto;
  width: 100%;
}

.custom-font-size {
  font-size: 19.5px !important; /* Adjust the size as needed */
}

.custom-font-size2 {
  font-size: 18px !important; /* Adjust the size as needed */
}

.custom-font-size3 {
  font-size: 17px !important; /* Adjust the size as needed */
}

.custom-image-col {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  height: auto; /* Maintain aspect ratio */
  padding: 0;
  
}

.WhatWeDo-title {
  margin-bottom: -50px; /* Move the title closer to the text */
}

.WhatWeDo-description {
  margin-top: -30px; /* Move the text upwards */
}

.custom-image-col-test{
  width: 100%;
  max-width: 1000px; /* or any desired width */
}

.custom-card {
  height: 100%; /* Ensures the card takes up the full height of the container */
  display: flex;
  flex-direction: column; /* Allows for content inside to stack */
}


.custom-card-img {
  width: 100%; /* Make the image as wide as the card */
  height: 100%; /* Make the image as tall as the card */
  object-fit: cover; /* Ensure the image maintains proportions while filling */
  border-radius: inherit; /* Matches any card border radius */
}


.custom-card-img-width {
  width: 100% !important; /* Set your desired height */
  object-fit: cover; /* Ensure image covers the area */
}

/* -------------------------------------------------------------------- */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.name.card-title.h5 {
  font-size: 3rem;
  padding-top: 2rem;
}

.title {
  color: #8c8c00;
  padding-top: 20px;
}

.description {
  margin-top: 10%;
  margin-bottom: 3%;
}

.link {
  color: #8c8c00;
  text-decoration: none;
  margin-left: 0%;
}

.headers {
  text-align: center;
}

.table-of-contents {
  font-size: 2rem;
}

.table-chapters {
  text-decoration: underline;
}

.SponsorCard {
  margin-top: 5%;
  margin-bottom: 5%;
}

.goldTier {
  color: #8c8c00;
}

.silverTier {
  color: #92a9bd;
}

.bronzeTier {
  color: #9d5c0d;
}

.events-page-container {
  display: flex;
  flex-flow: column;
  align-items: center; 
  justify-content: center;
}

.add-calendar-button {
  max-height: fit-content;
  max-width: fit-content;
  padding: 15px;
}

.nav-add-calendar {
  color: #700ada;
}


.events-list {
  display: flex;
  flex-flow: center wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 5%;
  width: 90%;
}

.event-card {
  margin: 10px 5px;
  max-width: 300px;
  min-width: 200px;
}

.seasonal-display {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
}

.seasonal-event-card {
  margin: 10px 5px;
  /** figure out how to change settings based on size of window*/
  position: relative;
  max-width: 300px;
  min-width: 200px;
}

.about-intro {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

